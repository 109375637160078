import "./budget.style.css";
import { Footer, Switch } from "../../Components/index";
import logo from "../../../assets/ico/logo.svg";
import { useState } from "react";
import { useFormInputs, useStates, useCities, useNotify } from "../../../hook";
import { addBudget } from "../../../api/budget/addBudget.api";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function Budget() {
  const [gender, setGender] = useState("Masculino");
  const [maritalStatus, setMaritalStatus] = useState("Solteiro(a)");
  const [hasChildren, setHasChildren] = useState(false);
  const [instagramFollower, setinstagramFollower] = useState(false);
  const { states, fetchStates } = useStates();
  const { notify } = useNotify();
  const { formInputs, handleChange } = useFormInputs({
    name: "",
    profession: "",
    age: 0,
    email: "",
    instagram: "",
    immobileArea: 0,
    lotArea: 0,
    projectTypology: "",
    flooring: 0,
    projectDescription: "",
    city: "",
    state: "",
    childrenQuantity: -1,
  });
  const { cities, fetchCities } = useCities(formInputs.state, states);

  const HandleChangeGender = () => {
    if (gender === "Masculino") {
      setGender("Feminino");
    } else {
      setGender("Masculino");
    }
  };
  const ShowGender = () => {
    return gender;
  };

  const HandleChangeMaritalStatus = () => {
    if (maritalStatus === "Solteiro(a)") {
      setMaritalStatus("Casado(a)");
    } else {
      setMaritalStatus("Solteiro(a)");
    }
  };

  const handleChangeChildren = () => {
    setHasChildren(!hasChildren);
  };

  const handleChangeInstagramFollower = () => {
    setinstagramFollower(!instagramFollower);
  };

  const showChildren = () => {
    if (hasChildren) return "Sim";
    return "Não";
  };

  const showFollow = () => {
    if (instagramFollower) return "Sim";
    return "Não";
  };

  async function handleSubmit(event) {
    event.preventDefault();
    toast.loading("Conectando...");
    let budgetRequest = {
      name: formInputs.name,
      gender: gender,
      maritalStatus: maritalStatus,
      profession: formInputs.profession,
      age: parseInt(formInputs.age),
      hasChildren: hasChildren,
      childrenQuantity: parseInt(formInputs.childrenQuantity),
      email: formInputs.email,
      instagram: formInputs.instagram,
      instagramFollower: instagramFollower,
      projectTypology: formInputs.projectTypology,
      flooring: parseInt(formInputs.flooring),
      state: formInputs.state,
      city: formInputs.city,
      local: formInputs.local,
      immobileArea: parseInt(formInputs.immobileArea),
      lotArea: parseInt(formInputs.lotArea),
      projectDescription: formInputs.projectDescription,
    };
    try {
      const response = await addBudget(budgetRequest);
      toast.dismiss();
      notify(
        `Olá ${response.data.name}, recebemos suas informações. Nosso prazo para envio ou apresentação do orçamento é de até 72h (3 dias)`
      );
    } catch (error) {
      toast.dismiss();
      error.response?.data?.message
        ? notify(error.response.data.message)
        : notify("Tempo limite excedido");
    }
  }

  useEffect(() => {
    fetchStates();
  }, []);

  useEffect(() => {
    fetchCities();
  }, [formInputs.state]);

  return (
    <>
      <main className="budget_main">
        <section className="budget_main--section">
          <section className="budget_main--top_section">
            <img className="budget_logo--img" src={logo} alt="logo do site" />
            <div className="budget_title--div">
              <strong className="budget_title--text">
                Um mundo de possibilidades.
              </strong>
              <strong className="budget_title--text">
                Uma assinatura inconfundível.
              </strong>
            </div>
            <div className="budget_info--div">
              <span className="budget_info--text">
                Para gerar seu orçamento, precisamos conhecer você um pouco
                melhor.
              </span>
              <strong className="budget_info--strong">
                Vai ser rápido e levará menos de 3 minutos
              </strong>
            </div>
          </section>
          <form className="budget_form" onSubmit={handleSubmit}>
            <div className="budget_form--subtitle_about">
              <strong className="budget_form--title_text">Sobre você</strong>
            </div>
            <div className="budget_form--div input">
              <input
                className="budget_form--input budget_form--subtitle_text"
                placeholder="Nome"
                name="name"
                type="text"
                onChange={handleChange}
              />
            </div>
            <div className="budget_form--div toogle_input--div">
              <span className="budget_form--subtitle_text">Sexo</span>
              <div className="budget_form--input_div">
                <span className="budget_input--text">{ShowGender()}</span>
                <Switch setState={HandleChangeGender} />
              </div>
            </div>
            <div className="budget_form--div toogle_input--div">
              <span className="budget_form--subtitle_text">Estado civil</span>
              <div className="budget_form--input_div">
                <span className="budget_input--text">{maritalStatus}</span>
                <Switch setState={HandleChangeMaritalStatus} />
              </div>
            </div>
            <div className="budget_form--div input">
              <input
                className="budget_form--input budget_form--subtitle_text"
                placeholder="Profissão"
                name="profession"
                type="text"
                onChange={handleChange}
              />
            </div>
            <div className="budget_form--div input">
              <input
                className="budget_form--input budget_form--subtitle_text"
                type="number"
                min="18"
                max="110"
                placeholder="Idade"
                name="age"
                onChange={handleChange}
              />
            </div>
            <div className="budget_form--div toogle_input--div">
              <span className="budget_form--subtitle_text">Possui filhos?</span>
              <div className="budget_form--input_div">
                <span className="budget_input--text">{showChildren()}</span>
                <Switch setState={handleChangeChildren} />
              </div>
            </div>
            {hasChildren ? (
              <div className="budget_form--div input">
                <span className="budget_form--subtitle_text">
                  Quantos filhos?
                </span>
                <select
                  value={formInputs.childrenQuantity}
                  onChange={handleChange}
                  className="budget_form--select"
                  name="childrenQuantity"
                >
                  <option value={-1} disabled>
                    Selecione a quantidade de filhos
                  </option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </div>
            ) : null}
            <div className="budget_form--div input">
              <input
                className="budget_form--input budget_form--subtitle_text"
                placeholder="E-mail"
                name="email"
                type="email"
                onChange={handleChange}
              />
            </div>
            <div className="budget_form--div input">
              <input
                className="budget_form--input budget_form--subtitle_text"
                placeholder="Qual o @ do seu Instagram"
                name="instagram"
                type="text"
                onChange={handleChange}
              />
            </div>
            <div className="budget_form--div toogle_input--div">
              <span className="budget_form--subtitle_text">
                Você nos segue no Instagram?
              </span>
              <div className="budget_form--input_div">
                <span className="budget_input--text">{showFollow()}</span>
                <Switch setState={handleChangeInstagramFollower} />
              </div>
            </div>
            <div className="budget_form--subtitle_about">
              <strong className="budget_form--title_text">
                Sobre o projeto
              </strong>
            </div>
            <div className="budget_form--div input">
              <span className="budget_form--subtitle_text">
                Tipologia do projeto
              </span>
              <select
                className="budget_form--select"
                name="projectTypology"
                onChange={handleChange}
                value={formInputs.projectTypology}
              >
                <option value="" disabled>
                  Selecione a tipologia do projeto
                </option>
                <option>Projeto de residência</option>
                <option>Projeto comercial</option>
                <option>Projeto de clínica/hospital</option>
                <option>Projeto de edifício comercial</option>
                <option>Projeto de edifício residencial</option>
                <option>Projeto de edifício de uso misto</option>
                <option>Outra tipologia</option>
              </select>
            </div>
            {formInputs.projectTypology === "Projeto de edifício residencial" ||
            formInputs.projectTypology ===
              "Projeto de edifício de uso misto" ? (
              <div className="budget_form--div input">
                <input
                  className="budget_form--input budget_form--subtitle_text"
                  type="number"
                  placeholder="Digite a quantidade de pavimentos"
                  min={0}
                  name="flooring"
                  onChange={handleChange}
                />
              </div>
            ) : null}
            {formInputs.projectTypology === "Outra tipologia" ? (
              <div className="budget_form--div input">
                <input
                  className="budget_form--input budget_form--subtitle_text"
                  type="text"
                  placeholder="Digite a tipologia do projeto"
                  name="projectTypology"
                  onChange={handleChange}
                />
              </div>
            ) : null}
            <div className="budget_form--div input">
              <span className="budget_form--subtitle_text">
                Descrição do projeto
              </span>
              <select
                className="budget_form--select"
                name="projectDescription"
                onChange={handleChange}
                value={formInputs.projectDescription}
              >
                <option value="" disabled>
                  Selecione a descrição do projeto
                </option>
                <option>Arquitetônico e interiores</option>
                <option>Somente arquitetônico</option>
              </select>
            </div>
            {formInputs.projectDescription === "Arquitetônico e interiores" ? (
              <div className="budget_form--div input">
                <input
                  className="budget_form--input budget_form--subtitle_text"
                  placeholder="Quantos m² possui o lote?"
                  type="number"
                  name="lotArea"
                  onChange={handleChange}
                />
              </div>
            ) : null}
            {formInputs.projectDescription === "Somente arquitetônico" ? (
              <div className="budget_form--div input">
                <input
                  className="budget_form--input budget_form--subtitle_text"
                  placeholder="Quantos m² possui o imóvel?"
                  type="number"
                  min={0}
                  name="immobileArea"
                  onChange={handleChange}
                />
              </div>
            ) : null}
            <div className="budget_form--div input">
              <span className="budget_form--subtitle_text">
                Qual o estado do projeto?
              </span>
              <select
                value={formInputs.state}
                className="budget_form--select"
                name="state"
                onChange={handleChange}
              >
                <option value="" disabled>
                  Selecione o estado
                </option>
                {states.map((state, index) => {
                  return <option key={index}>{state.nome}</option>;
                })}
              </select>
            </div>
            {formInputs.state === "" ? null : (
              <div className="budget_form--div input">
                <span className="budget_form--subtitle_text">
                  Qual a cidade do projeto?
                </span>
                <select
                  className="budget_form--select"
                  name="city"
                  value={formInputs.city}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Selecione a cidade do projeto
                  </option>
                  {cities?.map((city, index) => {
                    return <option key={index}>{city}</option>;
                  })}
                </select>
              </div>
            )}
            <div className="budget_form--div input">
              <input
                placeholder="Nome do condomínio e/ou bairro que o projeto será desenvolvido"
                className="budget_form--input budget_form--local_text"
                type="text"
                name="local"
                onChange={handleChange}
              />
            </div>
            <div className="budget_form--button_div">
              <button className="budget_form--button">Enviar</button>
            </div>
          </form>
        </section>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ textAlign: "justify" }}
        />
      </main>
      <Footer />
    </>
  );
}
